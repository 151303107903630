import React, { Component } from 'react';
import { firebase, FirebaseManager } from "../../common/FirebaseManager";
import styles from './Login.module.css';
import "../../BasicUI.css";
import LoginSheet from "../../component/loginSheet/LoginSheet.js";
import Footer from '../../component/footer/Footer';

class Login extends Component { alt="description"
    render() {
        return (
            <div className={"noselect"}>
                <div className = {styles.loginContent}>
                    <div className = {styles.loginLeft}>
                        <div className = {styles.login_intro}>
                            <img src= "logo.svg" alt="description" type= "image/svg+xml" width= "250px"/>
                            <p>Vocabear，讓你和親朋好友保持聯繫，隨時分享生活中的每一刻。</p>
                        </div>
                    </div>
                    <div className = {styles.loginRight}>
                        <div className = {styles.loginSheetBackground}>
                            <LoginSheet/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default Login;
