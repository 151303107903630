import React, { Component } from 'react';
import styles from './Footer.module.css';
import "../../BasicUI.css";

function Footer(){
    const items = [
      {
        text: "關於",
        herf: "/about",
      },{
        text: "使用說明",
        herf: "/use",
      },{
        text: "新聞稿",
        herf: "/news",
      },{
        text: "API",
        herf: "/api",
      },{
        text: "隱私",
        herf: "/privacy",
      },{
        text: "使用條款",
        herf: "/terms",
      },
    ]

    const data = items.map((item, index) => {
      return (
        <li key={index}>
            <a href= {item.herf} target="_blank" rel="noreferrer noopener">{item.text}</a>
        </li>
      )
    })
    return(
        <div className={styles.loginFooter}>
            <ul className={"noselect"}>
                {data}
              </ul>
            <ul className={"noselect"}>
                <li>
                    <p>Vocabear © {(new Date().getFullYear())}</p>
                </li>
            </ul>
        </div>
    )
}
export default Footer;
