import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navStyle from './NavigationBar.module.css';
import menu from "../../assets/menu.svg";
import { firebase, FirebaseManager } from '../../common/FirebaseManager';

class NavigationBar extends Component {

  constructor(props) {
    super(props);
    const logo = {src: "logo.svg", type:"image/svg+xml"};

    const navItems = [
      {title: 'Home', href:"/home"},
      {title: 'Templates', href:"/templates"},
      {title: 'Features', href:"/features"},
      {title: 'Premium', href:"/premium"},
      {title: 'Pricing', href:"/pricing"}
    ];

    const navLoginItems = [
      {title: 'Login', href:"/premium"},
      {title: 'Signup', href:"/pricing"}
    ];

    const leftItems = navItems.map((item, index) => {
      if (index === 0){
        return (
          <li key={index}>
            <Link to={item.href}>
              <img alt={"logo"} src={logo.src} type={logo.type}/>
            </Link>
          </li>
        )
      }else{
        return (
          <li key={index}>
            <Link to={item.href}>{item.title}</Link>
          </li>
        )

      }}
    );
    
    var rightItems = navLoginItems.map((item, index) => {
      return (
        <li key={index}>
          <span className="noselect" onClick={this.showLoginSheet}>{item.title}</span>
        </li>
      )
    });

      this.state = {
        leftNavs : leftItems,
        rightNavs : rightItems,
        stackDisplay: false,
      };

      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          console.log("User is signed in.");
        } else {

        }
      });
    }

    render() {
      return (
        <header>
          <div>
            <div id={navStyle.navigationDesktop}>
              <nav>
                <ul>{this.state.leftNavs}</ul>
                <ul>{this.state.rightNavs}</ul>
              </nav>
            </div>
            <div id={navStyle.navigationMobile}>
              <nav>
                <ul>{this.state.leftNavs[0]}</ul>
                <ul>
                  <li>
                    <img src={menu} alt="description" type="image/svg+xml" onClick={this.showStack} />
                  </li>
                </ul>
              </nav>
              <ul id={navStyle.stack} style={{
                  "display": this.state.stackDisplay ? 'block' : 'none',
                }}>{this.state.leftNavs}{this.state.rightNavs}</ul>
            </div>
          </div>
        </header>
      );
    }

    showLoginSheet = () => {
      document.getElementById('blurBackground').style.display = "block";
    }

    showStack = () => {
      this.setState({
        stackDisplay : !(this.state.stackDisplay),
      })
    }
  }

export default NavigationBar;
