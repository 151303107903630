import React, { Component } from 'react';

class Templates extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{height:'1000px',width:'100%',backgroundColor:'rgb(235, 235, 235)'}}></div>
        );
    }
}

export default Templates;
