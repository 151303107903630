import React, { Component } from 'react';
import "../../BasicUI.css";
import {firebase, FirebaseManager} from '../../common/FirebaseManager'

class DocPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount () {
      // id: "ka63Pv0KpT71AajEMr63"
      const _this = this;
      FirebaseManager.instance.getPageDoc(this.props.match.params.id, function(data){
        _this.setState({
          data: data,
          })
      })
    }

    render() {
      const wordItems = this.state.data?.words_index.map((word, index) => {
        return (
          <div key={index} className={"fadeIn"} style={{backgroundColor:'white',height:'120px',textAlign: 'center',borderRadius: 12}}>
            <p style={{fontWeight: '700'}}>{word}</p>
            <p>{this.state.data?.content[word].def}</p>
          </div>
        )
      })
        return(
          <>
            <div className={"fadeIn"} style={{boxSizing: 'border-box', padding: '0px 20px 0px 20px', flex: 1}}>
              <div style={{height:'200px'}}>
                <h3>ID: {this.props.match.params.id}</h3>
                <h3>{this.state.data?.page_title}</h3>
                <h3>{this.state.data?.words_index.length} items</h3>
              </div>
              <div>{wordItems}</div>
              <div style={{height:'200px'}}></div>
            </div>
            <div className="playListContolMenu" style={{bottom:0, width: '100%', height:60, position:'fixed', backgroundColor:'white'}}>
            </div>
          </>
        )
    }
}

export default DocPage;
