import React, { Component } from 'react';
import "../../BasicUI.css";

class Home extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
      const title = "Vocabear Office Web"

        return (
            <div style={{width:'100%',backgroundColor:'#F7F7F7'}}>
                <section style={{width:'100%'}}>
                  <div style={{marginTop:'60px',width:'100%',paddingTop:'120px',paddingBottom:'70px'}}>
                    <h1 style={{padding: '120pxw',textAlign:'center',fontSize:52}}>{title}</h1>
                  </div>
                  <div style={{width:'100%',textAlign:'center',paddingBottom:'200px'}}>
                    <a className={"noselect"} style={{backgroundColor:'orange',padding:'10px 20px 10px 20px',borderRadius: '10px',color:'white',fontWeight:'600',cursor: 'pointer'}}>立刻加入</a>
                  </div>
              </section>
            </div>
        );
    }
}

export default Home;
