import React from "react";

function PageNotFound() {
  const style = {
    height: "90vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const container = {
    width: "200px",
    display: "flex",
    flexDirection: "colnum",
    alignItems: "center",
  };
  const iconStyle = {
    width: "80px",
    height: "80px",
  };
  const textStyle = {
    color: "#C1C1C1",
    fontSize: "42px",
    marginLeft: "20px",
  };

  return (
    <div style={style}>
      <div style={container}>
        <img src="icon/icon-128x128.ico" alt="" style={iconStyle} />
        <h1 style={textStyle}>404</h1>
      </div>
    </div>
  );
}

export default PageNotFound;
