import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../BasicUI.css';
import styles from './AppNavigation.module.css'
import {firebase, FirebaseManager} from '../../common/FirebaseManager'

class AppNavigation extends Component {

  signOut = () => {
    FirebaseManager.instance.userSignOut()
    this.props.history.push("/")
  }
  render() {
    const tabData = [
      {
        title: "Dashboard",
        src: require("../../assets/AppNavigation/Dashboard.png"),
      },
      {
        title: "Collection",
        src: require("../../assets/AppNavigation/Collection.png"),
      },
      {
        title: "Explore",
        src: require("../../assets/AppNavigation/Explore.png"),
      },
      {
        title: "More",
        src: require("../../assets/AppNavigation/More.png"),
      },
      {
        title: "Setting",
        src: require("../../assets/AppNavigation/Setting.png"),
      },
    ];
      const navigationItem = tabData.map( (item, index) => {
          return (
            <Link style={{textDecoration: 'none'}} to={'/' + item.title.toLowerCase()} key={index}>
              <li>
                <img className={styles.tabListItemImg} alt="description" src={item.src} style={{padding:'3px',boxSizing: 'border-box'}}/>
                <p>{item.title}</p>
              </li>
            </Link>
          )
      });

      return(
            <div className = {styles.AppNavigation}>
                <div className = {styles.tabList}>
                    <ul>{navigationItem}</ul>
                </div>
                <div className= {styles.userProfile}>
                    <img className={styles.noselect} alt="description" src={FirebaseManager.instance.getFirestoargeImageUrl(FirebaseManager.instance.state.userProfile?.user_img)}/>
                    <span className={styles.noselect}>{FirebaseManager.instance.state.userProfile?.nick_name}</span>
                    <div className={"noselect"}onClick={this.signOut}>︙</div>
                </div>
            </div>
      )
  }
}

export default AppNavigation;
