// import * as admin from 'firebase-admin';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBg65MnDy_LuQ7VwAfI0Eeg7xyPFBdE4HM",
    authDomain: "vocabear.com",//, "vocabear-e4e0a.firebaseapp.com"
    databaseURL: "https://vocabear-e4e0a.firebaseio.com",
    projectId: "vocabear-e4e0a",
    storageBucket: "vocabear-e4e0a.appspot.com",
    messagingSenderId: "861215000386",
    appId: "1:861215000386:web:a51ebc9a33382b92462adb",
    measurementId: "G-EJKD2Y7KNY"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();

class FirebaseManager {
    static instance = new FirebaseManager();

    state = {
        'userProfile': undefined
    }
    //init
    constructor(){
        console.log("constructor is Calling");
        this.checkUserIsLogin();
    }

    checkUserIsLogin = () => {
        const _this = this
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                console.log("User is signed in.");
                user.getIdToken().then(function(idToken) {
                    _this.updateChromeCookie(idToken, user.uid);
                });
                _this.observerUserProfile();
            } else {
                // No user is signed in.
                console.log("No user is signed in.");
                _this.updateChromeCookie("", "");
                _this.removeObserverUserProfile();
            }
        });
    }

    facebookLogin = () => {
        console.log("FacebookLogin");
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            // ...
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });
    }

    googleLogin = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            console.log("token",token);
            // The signed-in user info.
            var user = result.user;
            // ...
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            console.log(errorCode);
            var errorMessage = error.message;
            console.log(errorMessage);
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });
    }

    appleLogin = () => {
      var provider = new firebase.auth.OAuthProvider('apple.com');
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;

          // The signed-in user info.
          var user = result.user;

          // You can also get the Apple OAuth Access and ID Tokens.
          var accessToken = credential.accessToken;
          var idToken = credential.idToken;

          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;

          // ...
        });

    }

    userSignOut = () => {
        firebase.auth().signOut().then(function() {
            console.log('Signed Out');
        }, function(error) {
            console.error('Sign Out Error', error);
        });
    }

    updateChromeCookie = (idToken, uid) => {
        document.cookie = "idToken="+idToken+";";
        document.cookie = "uid="+uid+";"
        console.log("idtoken: ",idToken);
        console.log("uid: ",uid);
    }

    observerUserProfile = () => {
        if (!firebase.auth().currentUser){
            console.log('user not sign');
            return
        }

        var uid = firebase.auth().currentUser.uid;
        const userDocRef = db.collection("users").doc(uid);

        if (this.state.userProfileObserver) {
            // Fix: remove observer
            // this.state.userProfileObserver.removeAllObservers()
        }

        this.state.userProfileObserver = userDocRef.onSnapshot(docSnapshot => {
            console.log(`Received doc snapshot: ${docSnapshot}`);
            if (docSnapshot.exists) {
                this.state.userProfile = docSnapshot.data();
                console.log("Document data:", docSnapshot.data());
            } else {
                this.state.userProfile = null;
                console.log("No such document!");
            }
        }, err => {
            console.log(`Encountered error: ${err}`);
        });
    }

    removeObserverUserProfile = () => {
    // this.state.userProfileObserver.removeAllObservers()
      this.state.userProfile = undefined
    }

    getPageDoc = (pageId, handler) => {
      //Todo 使用者權限處理
      if (!firebase.auth().currentUser){
          console.log('user not sign');
          return
      }

      const pageDocRef = db.collection("pages").doc(pageId);
      pageDocRef.get().then((doc) => {
      if (doc.exists) {
          console.log("Document data:", doc.data());
          handler(doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }

    getFirestoargeImageUrl = (dir) => {
      var tempdir = (dir === undefined) ? "" : dir
      return "https://firebasestorage.googleapis.com/v0/b/vocabear-e4e0a.appspot.com/o/"+ tempdir.replace(/\//g, "%2F") +"?alt=media"
    };

    getLoginQRCodeTextAndListen = (urlHandler) => {
        const url = "https://us-east1-vocabear-e4e0a.cloudfunctions.net/oath/OathQRCode"
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        
        fetch( url, requestOptions)
        .then(response => response.json())
        .then(data => {
            const listenUrl = "https://us-east1-vocabear-e4e0a.cloudfunctions.net/oath/OathScanListener"

            urlHandler(data.url)

            const postBody = {
                'channel_id': data.url.split("channelId=")[1],
            }
            const listenRequestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postBody),
            }

            return fetch( listenUrl, listenRequestOptions)
        })
        .then(response => response.json())
        .then( data => {
            const customToken = data.token
            console.log("succeed GetcustomToken" + customToken);
            firebase.auth().signInWithCustomToken(customToken)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
            });
        })
    }
}

export { firebase , FirebaseManager};
