import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ISO6391 from 'iso-639-1';
import "../../BasicUI.css";
import styles from './CollectionPage.module.css';
import {firebase, FirebaseManager} from '../../common/FirebaseManager'

function CollectionPageRightMenu(props){

  const collectionSideData = [
    {
      title: "建立詞集",
      src: require("../../assets/collectionRightMenu/CreatePage.png"),
    },
    {
      title: "新增資料夾",
      src: require("../../assets/collectionRightMenu/CreateFolder.png"),
    },
    {
      title: "建立班級",
      src: require("../../assets/collectionRightMenu/CreateClassroom.png"),
    },
  ];

  const collectionSideItems = collectionSideData.map((item, index) => {
    return (
      <li key={index} style={{cursor: 'pointer',listStyle: 'none',padding:'5px 0 0 0', display: 'flex', flexDirection: 'row',height:'40px'}}>
        <img className="noselect" src={item.src} alt="description" style={{height: '28px', width: '28px',padding:'16px 5px 0 0',}}/>
        <span style={{font:18,fontWeight:'600',color:'#007AFF',}}>{item.title}</span>
      </li>
    )
  });

  return (
      <div className={styles.collectionSide} style={{backgroundColor:'#efefef'}}>
        <ul style={{margin:'240px 0px 0px 0px', paddingLeft:'18px', display: 'flex', flexDirection: 'column'}}>
          <p>{collectionSideItems}</p>
        </ul>
      </div>
  )
}

class CollectionPage extends Component {
    constructor(props){
      super(props);
      this.state = {
        fromLanguage: "All",
        toLanguage: "All",
      }
    }

    presntPage = (item) => {
        console.log(item.page_title);
    };

    render() {
        const pageItems = (FirebaseManager.instance.state.userProfile?.pages_index ?? []).map( item => {
            return FirebaseManager.instance.state.userProfile?.pages[item]
        });

        var fromLanguage = ["All"]
        var toLangugage = ["All"]

        for (var i = 0; i < pageItems.length-1 ; i++){
          let fromLanguageCode = pageItems[i].source_language.split("-")[0]
          if (!fromLanguage.includes(fromLanguageCode)) {
            fromLanguage.push(fromLanguageCode);
          }
          let toLanguageCode = pageItems[i].destination_language.split("-")[0]
          if (!toLangugage.includes(toLanguageCode)) {
            toLangugage.push(toLanguageCode);
          }
        }

        let fromItems = fromLanguage.map( (item, index) => {
          var text = item !== "All" ? ISO6391.getNativeName(item) : "All"
            return (
              <li key={index+1} onClick={()=> {
                this.setState({
                  fromLanguage : text,
                })
                }}>
                <span className={`${styles.languageItem} noselect ${text === this.state.fromLanguage ? styles.selected : "" }`}>
                  {text}
                </span>
              </li>
            )
        });

        let toItems = toLangugage.map( (item, index) => {
          var text = item !== "All" ? ISO6391.getNativeName(item) : "All"
            return (
              <li key={index+1} onClick={()=> {
                  this.setState({
                    toLanguage : text,
                  })
                }}>
                <span className={`${styles.languageItem} noselect ${text === this.state.toLanguage ? styles.selected : "" }`}>
                  {text}
                </span>
              </li>
            )
        });

        const navigationItem = pageItems.map( (item, index) => {
            return (
              <Link key={index} to={`/page/${FirebaseManager.instance.state.userProfile.pages_index[index]}`} style={{textDecoration: 'none'}}>
              <li className={"basicBackView"} style={{flexDirection:'column'}} onClick={() => this.presntPage(item)}>
                  <img className={"noselect"} alt="description" src={FirebaseManager.instance.getFirestoargeImageUrl(item.page_img)}/>
                  <div style={{flexDirection: 'row',boxSizing:'border-box',padding:'8px'}}>
                    <p className={"noselect"} style={{margin:0,}}>{item.page_title}</p>
                    <p className={"noselect"} style={{margin:0,fontWeight:'500',fontSize:14,}}>{item.description}</p>
                  </div>
              </li>
            </Link>
            )
        });

        return(
            <div className={`${styles.collectionMain} fadeIn`}>
                <div className={styles.collectionContent}>
                    <div className={styles.languageFilter}>
                        <h2 className="noselect">From</h2>
                        <ul>
                          {fromItems}
                        </ul>
                        <h2 className="noselect">To</h2>
                        <ul>
                          {toItems}
                        </ul>
                    </div>
                    <div className={styles.pageItems}>
                            <ul>{navigationItem}</ul>
                    </div>
                </div>
                <CollectionPageRightMenu/>
            </div>
        )
    }
}

export default CollectionPage;
