import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './page/login/Login';
import AppNavigation from './component/appNavigation/AppNavigation';
import NavigationBar from './component/navigationBar/NavigationBar';
import CollectionPage from './page/collectionPage/CollectionPage';
import DocPage from './page/docPage/DocPage';
import { firebase, FirebaseManager } from './common/FirebaseManager';
import { useLocation } from 'react-router-dom';
import './App.css';

import Home from './page/home/Home';
import PageNotFound from "./page/collectionPage/PageNotFound";
import Templates from './page/home/Templates';
//Component
import Footer from './component/footer/Footer';
import LoginSheet from './component/loginSheet/LoginSheet';

function HomeLoginSheet(){
  const style = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'red',
    'webkit-backdrop-filter': 'blur(7px)',
    'backdrop-filter': 'blur(10px)', /* Chrome and Opera */
    'background-color': 'rgba(0, 0, 0, 0.3)',
    display: 'none',
    zIndex: 300,
  }
  return(
    <div id="blurBackground" style={style} onClick={() => {
        document.getElementById('blurBackground').style.display = "none"
      }}>
      <LoginSheet/>
    </div>
  )
}

function Pending(){
  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-50px',
      marginLeft: '-50px',
      width: '100px'
  };
  const iconStyle = {
      width: '80px',
      height: '80px',
      marginLeft: '10px'
  };

  return(
    <div style={style}>

      <img src={require('./assets/icon.png')} alt="" style={iconStyle}/>
    </div>
  )
}

function HomePage(){
  return (
    <div>
      <NavigationBar />
      <HomeLoginSheet />
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/templates" element={<Templates />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function AppHomePage(){
  return (
    <div>
      <NavigationBar />
      <div className={"appContainer"}>
        <div className="leftComponent">
          <AppNavigation />
        </div>
        <div className="rightComponet">
          <Routes>
            <Route path="/collection" element={<CollectionPage />} />
            <Route path="/page/:id" element={<DocPage />} />
            <Route element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

class App extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      firebase.auth().onAuthStateChanged((user) => {
          if (user){
              console.log('setState to True');
              this.setState({"user": true});
          }else{
              console.log('setState to False');
              this.setState({"user": false});
          }
      });
  }

    render(){
        if (window.location.pathname === "/login"){
          switch (this.state.user) {
            case true:
              return ( <AppHomePage/> );
            default:
              return (
                <div>
                  <div className="container">
                    <Routes>
                      <Route exact path="/login" element={<Login />} />
                    </Routes>
                  </div>
                </div>
              );
          }
        }else{
            switch (this.state.user) {
              case undefined:
                //登入狀態未定 Pending
                return <Pending />;
              case true:
                // 已登入狀態
                return <AppHomePage />;
              case false:
                // 未登入狀態
                return <HomePage />;
              default:
                return;
            }
        }
    }
}

export default App;
