import React, { Component } from 'react';
import LoginSheetStyles from './LoginSheet.module.css';
import googleIcon from "../../assets/socialIcon_Google.svg";
import facebookIcon from "../../assets/socialIcon_Facebook.svg";
import appleIcon from "../../assets/socialIcon_Apple.svg";
import "../../BasicUI.css";
import {firebase, FirebaseManager} from '../../common/FirebaseManager';
import QRCode from 'qrcode.react';

function LoginSubmitButton(props){
    return <button className={LoginSheetStyles.loginSubmit}
    type="button"
    style={{backgroundColor: props.backgroundColor, borderColor: props.backgroundColor === 'white' ? '#a8a8a8' : '#ffffff'}}
    onClick={props.onClick}>
    <div className={LoginSheetStyles.loginSubmitContainer}>
        {props.src != null ? <img src={props.src} alt="description" style={{height:22,width:22,paddingRight:'12px'}}/> : null}
        <span style={{color: props.color}}>{props.title}</span>
    </div>
    </button>
}

class LoginSheet extends Component {
    constructor(props){
        super(props);
        this.state = {
            url: "",
        }
    }
    componentDidMount(){
        FirebaseManager.instance.getLoginQRCodeTextAndListen( (qrcodeUrl) => {
            this.setState({
                url: qrcodeUrl,
            })
        })
    }

    render() {
        const submitButtonData = [
            //{title:"Login Checker", src: null, color: 'white', backgroundColor: 'orange', onClick: FirebaseManager.instance.checkUserIsLogin},
            {title:"Continue with Google", src: googleIcon, color: '#505050', backgroundColor: 'white', onClick: FirebaseManager.instance.googleLogin},
            {title:"Continue with Facebook", src: facebookIcon, color: 'white', backgroundColor: '#1877f2', onClick: FirebaseManager.instance.facebookLogin},
            {title:"Continue with Apple", src: appleIcon, color: 'white', backgroundColor: 'black', onClick: FirebaseManager.instance.appleLogin},
            //{title:"Sign Out", src: null, color: 'white', backgroundColor: 'red', onClick: this.userSignOut}
        ]

        const buttons = submitButtonData.map((item, index) => {
            return <LoginSubmitButton key={index} title={item.title} src={item.src} color={item.color} backgroundColor={item.backgroundColor} onClick={item.onClick}/>
        })

        return (
            <div className={`basicBackView ${LoginSheetStyles.loginSheetBackground}`} onClick={this.sheet}>
            <img className={LoginSheetStyles.sheetLogo} alt="description" src= "logo.svg" type= "image/svg+xml" width= "120px" autoComplete="username"/>
            <h1>登入</h1>
            <form>
                <input className={LoginSheetStyles.loginInput} placeholder="Email" type="username"/>
                <input className={LoginSheetStyles.loginInput} placeholder="Password" autoCapitalize= "off" type="password" autoComplete="current-password"/>
            </form>
                {buttons}
                <QRCode value={this.state.url} />
            </div>
        );
    }

    sheet(e) {
        e.stopPropagation()
    }

    userSignOut(e){
        FirebaseManager.instance.userSignOut(function(){
            console.log("callback");
        });
    }
}

export default LoginSheet;
